import React, { useState, useEffect } from 'react'
import { Link, Route, Switch } from 'react-router-dom'
import HarvestList from './harvest-list/HarvestList';
import HarvestLote from './harvest-lote/HarvestLote';
import HarvestPurchases from './harvest-purchases/HarvestPurchases';
import HarvestSales from './harvest-sales/HarvestSales';
import HarvestAccounting from './harvest-accounting/HarvestAccounting';
import HarvestOperation from './harvest-operation/HarvestOperation';

const Harvest = (props) => {

    const loc = props.location.pathname.split('/')[2];
    
    return (
        <div className="sheep">
            <div className="in-nav">
                <ul>
                    <Link className={loc === undefined ? 'active' : ''} to="/cosecha">Resumen</Link>
                    {/* <Link className={loc === 'ventas' ? 'active' : ''} to="/cosecha/ventas">Ventas</Link> */}
                    <Link className={loc === 'compras' ? 'active' : ''} to="/cosecha/compras">Compras / Gastos</Link>
                    {/* <Link className={loc === 'estado-cuenta' ? 'active' : ''} to="/cosecha/estado-cuenta">Estado de Cuenta</Link> */}
                </ul>
            </div>
            <div style={{ overflowY: 'auto', width: '100%'}}>
                <Switch>
                    <Route path="/cosecha" component={HarvestList} exact={true} />
                    <Route path="/cosecha/lotes/:id" component={HarvestLote} exact={true} />
                    <Route path="/cosecha/ventas" component={HarvestSales} exact={true} />
                    <Route path="/cosecha/compras" component={HarvestPurchases} exact={true} />
                    <Route path="/cosecha/estado-cuenta" component={HarvestAccounting} exact={true} />
                    <Route path="/cosecha/operacion/:id" component={HarvestOperation} exact={true} />
                </Switch>
            </div>
        </div>
    )
}

export default Harvest