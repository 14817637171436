import React, { useState, useEffect} from 'react'
import firebase from '../firebase'
import { Switch, Route, Link } from 'react-router-dom'

import Loader from './Loader';
import Sheep from './sheep/Sheep';
import SimplifiedSheep from './simplified-sheep/SimplifiedSheep';
import Harvest from './harvest/Havest';

const App = (props) => {

    const [firebaseInitialized, setFirebaseInitialized] = useState(false)
    const [isOpen, setIsOpen] = useState(true)

    useEffect(() => {
        firebase.isInitialized().then(val => {
            if (val) {
                firebase.userValidator().then(validator => {
                    if (validator === true) {
                        setFirebaseInitialized(val)
                    } else {
                        alert('Usuario no autorizado')
                        return logout()
                    }
                })
            } else {
                props.history.replace('/iniciar-sesion')
                return null
            } 
        })
    })

    const loc = props.location.pathname.split('/')[1];
    return firebaseInitialized !== false ? (
        <div className="app">
            <div className="sidenav">
                <img src="/images/ico.png" alt="Casa Lohr"/>
                <ul className={isOpen === true ? '' : 'close'}>
                    <Link className={loc === '' ? 'active' : ''} to="/"><i className="material-icons">dashboard</i><span>Dashboard</span></Link>
                    {/* <Link className={loc === 'borregos-beta' ? 'active' : ''} to="/borregos-beta"><i className="material-icons">adb</i><span>Borregos Beta</span></Link> */}
                    <Link className={loc === 'borregos' ? 'active' : ''} to="/borregos"><i className="material-icons">adb</i><span>Borregos</span></Link>
                    <Link className={loc === 'cosecha' ? 'active' : ''} to="/cosecha"><i className="material-icons">terrain</i><span>Cosecha</span></Link>
                    {/* <Link className={loc === 'compras' ? 'active' : ''} to="/compras"><i className="material-icons">add_shopping_cart</i><span>Compras</span></Link>
                    <Link className={loc === 'ventas' ? 'active' : ''} to="/ventas"><i className="material-icons">monetization_on</i><span>Ventas</span></Link>
                    <Link className={loc === 'contabilidad' ? 'active' : ''} to="/contabilidad"><i className="material-icons">assignment</i><span>Contabilidad</span></Link> */}
                </ul>
            </div>
            <main style={{ height: '100vh' }}>
                <header>
                    <span className="logo">Casa Lohr</span>
                    <button onClick={logout}><i className="material-icons">exit_to_app</i></button>
                </header>
                <div className="app__container">
                    <button onClick={() => setIsOpen(!isOpen)}>
                        <i className="material-icons">{isOpen === true ? 'close' : 'menu'}</i>
                    </button>
                    <Switch>
                        <Route path="/borregos-beta" component={Sheep} exact={true} />
                        <Route path="/borregos" component={SimplifiedSheep} />
                        <Route path="/cosecha" component={Harvest} />
                    </Switch>
                </div>
            </main>
        </div>
    ) : <Loader/>

    async function logout() {
        await firebase.logout()
        props.history.push('/iniciar-sesion')
    }
}

export default App