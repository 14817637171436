import React, { useState,useEffect } from 'react'
import firebase from '../../../firebase'
import numeral from 'numeral'
import moment from 'moment'

export default function HarvestSales () {

    const [sales,setSales] = useState([])
    
    useEffect(() => {
        firebase.getCollectionRealTime('harvest-sales').onSnapshot(snap => {
            const data = snap.docs.map(doc => doc.data())
            setSales(data)
        })
    },[])

    return (
        <div style={{ padding: '2.5rem' }}>
            <table>
                <thead>
                    <tr>
                        <th>Fecha</th>
                        <th>Cantidad</th>
                        <th>Monto</th>
                    </tr>
                </thead>
                <tbody>{
                    sales.map(item => <tr>
                        <td>{moment(item.date).format('DD-MM-YYYY')}</td>
                        <td>{item.quantity}</td>
                        <td>{numeral(item.amount).format('$0,0.00')}</td>
                    </tr>)
                }</tbody>
            </table>
        </div>
    )
}