import React, { useState, useEffect } from 'react'
import { Link, Route, Switch } from 'react-router-dom'
import firebase from '../../firebase'
import SheepList from './sheep-list/SheepList'

const Sheep = (props) => {

    const loc = props.location.pathname.split('/')[2];
    
    return (
        <div className="sheep">
            <div className="in-nav">
                <ul>
                    <Link className={loc === undefined ? 'active' : ''} to="/borregos">Ver/Agregar</Link>
                    <Link className={loc === 'corrales' ? 'active' : ''} to="/borregos/corrales">Corrales</Link>
                    <Link className={loc === 'Ventas' ? 'active' : ''} to="/borregos/Ventas">Agregar Ingreso</Link>
                    <Link className={loc === 'gastos' ? 'active' : ''} to="/borregos/gastos">Gastos</Link>
                    <Link className={loc === 'balance' ? 'active' : ''} to="/borregos/balance">Balance</Link>
                </ul>
            </div>
            <Switch>
                <Route path="/borregos" component={SheepList} exact={true} />
            </Switch>
        </div>
    )
}

export default Sheep