import React, { useState, useEffect } from 'react'
import firebase from '../../../firebase'
import numeral from 'numeral'
import moment from 'moment'
import _ from 'lodash'

export default function HarvestPurchases () {

    const [sales,setSales] = useState([])
    const [date, setdate] = useState('')
    const [amount, setamount] = useState('')
    const [concept,setconcept] = useState('')
    const [subCategory,setsubCategory] = useState('false')
    const [lotes,setlotes] = useState([])
    const [month,setMonth] = useState(undefined)
    const [filter,setfilter] = useState('false')

    useEffect(() => {
        const current = moment().format('MM-YYYY')
        setMonth(current)
        firebase.getCollectionRealTime('lotes').get().then(snap => {
            const data = snap.docs.map(doc => doc.data())
            setlotes(data)
        })
    }, [])
    
    useEffect(() => {
        if (month) {
            const initialMonth = moment(month,'MM-YYYY').startOf('month').valueOf()
            const endMonth = moment(month,'MM-YYYY').endOf('month').valueOf()

            // firebase.getCollectionRealTime('expenses').where('category','==','RANCHO').orderBy('date').onSnapshot(snap => {
            //     const data = snap.docs.map(doc => doc.data())
            //     setSales(data)
            // })

            if (initialMonth && endMonth) {
                firebase.getCollectionRealTime('expenses').where('category','==','RANCHO').where('date','<=',endMonth).where('date','>=',initialMonth).orderBy('date').onSnapshot(snap => {
                    const data = snap.docs.map(doc => doc.data())
                    setSales(data)
                })
            }
        }
    },[month])

    const filtered = filter !== 'false' ? _.filter(sales, o => {
        return o.subCategory === filter
    }) : sales

    const total = _.reduce(filtered, (sum,n) => {
        return sum + n.amount
    },0)

    return (
        <div style={{ padding: '2.5rem' }} className="simplified-sheep-sales">
            <p>Aquí agregar lo de las subcategorías pero ver con coy cuales</p>
            <form style={{ display: 'flex' }} onSubmit={e => e.preventDefault() && false}>
                <div className="input-container">
                    <label>Fecha</label>
                    <input onChange={e => setdate(e.target.value)} placeholder="Fecha" value={date} type="date" />
                </div>
                <div className="input-container">
                    <label>Concepto</label>
                    <input onChange={e => setconcept(e.target.value)} placeholder="Concepto" value={concept} type="text" />
                </div>
                {/* <div className="input-container">
                    <label>Cantidad</label>
                    <input onChange={e => setquantity(e.target.value)} placeholder="Cantidad" value={quantity} type="number" />
                </div> */}
                <div className="input-container">
                    <label>Sub-ategoría</label>
                    <select value={subCategory} onChange={e => setsubCategory(e.target.value)}>
                        <option value="false">** Selecciona una opción **</option>
                        {
                            lotes.map(item => <option key={item.id} key={item.id}>{item.alias}</option>)
                        }
                    </select>
                </div>
                <div className="input-container">
                    <label>Monto Total</label>
                    <input onChange={e => setamount(e.target.value)} placeholder="Valor Total de Venta" value={amount} type="number" />
                </div>
                <div className="input-container">
                    <button onClick={handleSubmit} type="submit">Agregar</button>
                </div>
            </form>
            <ul style={{ overflowX: 'auto' }} className="accounting-balance__filter">
                <li className={filter === 'false' ? 'active': ''} onClick={() => setfilter('false')}>Mostrar todos</li>
                {
                    lotes.map(item => <li className={filter === item.id ? 'active': ''} onClick={() => setfilter(item.id)} key={item.id}>{item.alias}</li>)
                }
            </ul>
            <table style={{ marginTop: '2.5rem' }}>
                <thead>
                    <tr>
                        <th colSpan="6">
                            <div className="date-picker">
                                <button onClick={prevMonth}>
                                    <i className="material-icons">arrow_left</i>
                                </button>
                                <span>{month}</span>
                                <button onClick={nextMonth}>
                                    <i className="material-icons">arrow_right</i>
                                </button>
                            </div>
                        </th>
                    </tr>
                    <tr>
                        <th>Fecha</th>
                        <th>Concepto</th>
                        <th>Categoría</th>
                        <th>Subcategoría</th>
                        <th>Monto</th>
                    </tr>
                </thead>
                <tbody>{
                    filtered.map(item => <tr key={item.id}>
                        <td>{moment(item.date).format('DD-MM-YYYY')}</td>
                        <td>{item.concept}</td>
                        <td>{item.category}</td>
                        <td>
                            <select value={item.subCategory} onChange={e => firebase.update('expenses',item.id,'subCategory',e.target.value)}>
                                <option value="false">** Selecciona una opción **</option>
                                {
                                    lotes.map(item => <option key={item.id} value={item.id}>{item.alias}</option>)
                                }
                            </select>
                        </td>
                        <td>{numeral(item.amount).format('$0,0.00')}</td>
                    </tr>)
                }</tbody>
                <tfoot>
                    <tr>
                        <th colSpan="3"></th>
                        <th>Total</th>
                        <td>{numeral(total).format('$0,0.00')}</td>
                    </tr>
                </tfoot>
            </table>
        </div>
    )

    async function handleSubmit () {
        try {
            const item = {
                date: moment(date, 'YYYY-MM-DD').valueOf(),
                // quantity: Number(quantity),
                amount: Number(amount),
                type: 'harvest',
                concept,
                category: 'RANCHO',
                brandName: 'EL MAYOR',
                paymentStatus: 'accepted',
                brandId: '9Kzs8p50WilD5fjxRtNg',
                subCategory
            }
            firebase.simpleAdd(item,'purchases').then(() => {
                setdate('')
                // setquantity('')
                setamount('')
                setconcept('')
                // setcategory('')
                alert('Compra registrada')
            })
        } catch (error) {
            alert(error.message)
        }
    }

    async function nextMonth() {
        try {
            const newMonth = moment(month, 'MM-YYYY').add(1,'month').format('MM-YYYY')
            setMonth(newMonth)
        } catch (error) {
            alert(error.message)
        }
    }
    async function prevMonth() {
        try {
            const newMonth = moment(month, 'MM-YYYY').subtract(1,'month').format('MM-YYYY')
            setMonth(newMonth)
        } catch (error) {
            alert(error.message)
        }
    }
}