import React, { useState, useEffect } from 'react'
import firebase from '../../../firebase'
import numeral from 'numeral'
import moment from 'moment'

export default function SimplifiedSheepSales () {

    const [sales,setSales] = useState([])
    const [date, setdate] = useState('')
    const [quantity, setquantity] = useState('')
    const [amount, setamount] = useState('')
    
    useEffect(() => {
        firebase.getCollectionRealTime('sheep-sales').onSnapshot(snap => {
            const data = snap.docs.map(doc => doc.data())
            setSales(data)
        })
    },[])

    return (
        <div style={{ padding: '2.5rem' }} className="simplified-sheep-sales">
            <form style={{ display: 'flex' }} onSubmit={e => e.preventDefault() && false}>
                <div className="input-container">
                    <label>Fecha</label>
                    <input onChange={e => setdate(e.target.value)} placeholder="Fecha" value={date} type="date" />
                </div>
                <div className="input-container">
                    <label>Cantidad</label>
                    <input onChange={e => setquantity(e.target.value)} placeholder="Cantidad" value={quantity} type="number" />
                </div>
                <div className="input-container">
                    <label>Valor Total de Venta</label>
                    <input onChange={e => setamount(e.target.value)} placeholder="Valor Total de Venta" value={amount} type="number" />
                </div>
                <div className="input-container">
                    <button onClick={handleSubmit} type="submit">Agregar</button>
                </div>
            </form>
            <table>
                <thead>
                    <tr>
                        <th>Fecha</th>
                        <th>Cantidad</th>
                        <th>Monto</th>
                    </tr>
                </thead>
                <tbody>{
                    sales.map(item => <tr>
                        <td>{moment(item.date).format('DD-MM-YYYY')}</td>
                        <td>{item.quantity}</td>
                        <td>{numeral(item.amount).format('$0,0.00')}</td>
                    </tr>)
                }</tbody>
            </table>
        </div>
    )

    async function handleSubmit () {
        try {
            const item = {
                date: moment(date, 'YYYY-MM-DD').valueOf(),
                quantity: Number(quantity),
                amount: Number(amount),
                type: 'sheep'
            }
            firebase.simpleAdd(item,'sheep-sales').then(() => {
                const move = {
                    date: moment(date,'YYYY-MM-DD').valueOf(),
                    concept: `Venta del ${moment(date, 'YYYY-MM-DD').format('DD-MM-YYYY')}`,
                    type: 'Salida',
                    quantity: Number(quantity),
                    cause: 'Venta'
                }
                firebase.simpleAdd(move,'sheep-moves').then(() => {
                    setdate('')
                    setquantity('')
                    setamount('')
                    alert('Venta registrada')
                })
            })
        } catch (error) {
            alert(error.message)
        }
    }
}