import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import firebase from '../../../firebase'
import numeral from 'numeral'
import moment from 'moment'
import _ from 'lodash'

const Item = ({ data }) => {

    const [process,setProces] = useState([])

    useEffect(() => {
        if (data.id) {
            firebase.getCollectionRealTime('harvest-process').where('seedId','==',data.id).get().then(snap => {
                const data = snap.docs.map(doc => doc.data())
                setProces(data)
            })
        }
    },[data.id])

    const totalProcess = _.reduce(process, (sum,n) => {
        if (n.status === 'En Proceso') {
            return sum + n.quantity
        } else {
            return sum
        }
    },0)
    const totalSeeded = _.reduce(process, (sum,n) => {
        if (n.status === 'Cosechado') {
            return sum + n.quantity
        } else {
            return sum
        }
    },0)

    return (
        <tr>
            <td>{data.name}</td>
            <td>{numeral(totalProcess).format('0,0.00')}</td>
            <td>{numeral(totalSeeded).format('0,0.00')}</td>
        </tr>
    )
}

const HarvestList = () => {

    const [lotes,setLotes] = useState([])
    const [seeds,setSeeds] = useState([])
    const [alias,setAlias] = useState('')
    const [name,setName] = useState('')

    useEffect(() => {
        firebase.getCollectionRealTime('lotes').orderBy('alias').onSnapshot(snap => {
            const data = snap.docs.map(doc => doc.data())
            setLotes(data)
        })
        firebase.getCollectionRealTime('seeds').onSnapshot(snap => {
            const data = snap.docs.map(doc => doc.data())
            setSeeds(data)
        })
    }, [])

    return (
        <div className="harvest-list">
            <div className="harvest-list__lotes">
                <form onSubmit={e => e.preventDefault() && false}>
                    <h3>Crear Lote</h3>
                    <div className="input-container">
                        <label>Alias de Lote</label>
                        <input placeholder="Alias de Lote" onChange={e => setAlias(e.target.value)} value={alias} type="text"/>
                    </div>
                    <div className="input-container">
                        <button onClick={handleSubmit} type="submit">Agregar Lote</button>
                    </div>
                </form>
                <hr/>
                <ul>
                    {lotes.map(item => <Link key={item.id} to={`/cosecha/lotes/${item.id}`}>{item.alias}</Link>)}
                </ul>
            </div>
            <div className="harvest-list__container">
                <form onSubmit={e => e.preventDefault() && false}>
                    <h3>Semillas</h3>
                    <div className="input-container">
                        <label>Nombre</label>
                        <input placeholder="Nombre" onChange={e => setName(e.target.value)} value={name} type="text"/>
                    </div>
                    <div className="input-container">
                        <button onClick={handleSeed} type="submit">Agregar Lote</button>
                    </div>
                </form>
                <table>
                    <thead>
                        <tr>
                            <th>Nombre</th>
                            <th>En Siembra</th>
                            <th>Cosechado</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            seeds.map(item => <Item key={item.id} data={item} />)
                        }
                    </tbody>
                </table>
            </div>
        </div>
    )

    async function handleSeed() {
        try {
            const item = {
                name
            }
            firebase.simpleAdd(item,'seeds').then(() => {
                setName('')
            })
        } catch (error) {
            alert(error.message)
        }
    }
    async function handleSubmit() {
        try {
            const item = {
                alias
            }
            firebase.simpleAdd(item,'lotes').then(() => {
                setAlias('')
            })
        } catch (error) {
            alert(error.message)
        }
    }
}

export default HarvestList