import React, { useState, useEffect } from 'react'
import firebase from '../../../firebase'
import numeral from 'numeral'
import moment from 'moment'

export default function HarvestOperation (props) {

    const id = props.match.params.id
    const [process,setProcess] = useState({})
    const [concept,setconcept] = useState('')
    const [amount,setamount] = useState('')
    const [quantity,setquantity] = useState('')
    const [description,setdescription] = useState('')
    const [sales,setSales] = useState([])
    const [memo,setMemo] = useState([])

    useEffect(() => {
        firebase.getDocumentOnce('harvest-process',id).then(snap => {
            setProcess(snap.data())
        })
        firebase.getCollectionRealTime('harvest-sales').where('processId','==',id).onSnapshot(snap => {
            const data = snap.docs.map(doc => doc.data())
            setSales(data)
        })
        firebase.getCollectionRealTime('harvest-memo').where('processId','==',id).onSnapshot(snap => {
            const data = snap.docs.map(doc => doc.data())
            setMemo(data)
        })
    },[id])

    return (
        <div className="harvest-operation">
            <div className="harvest-operation__sales">
                <form onSubmit={e => e.preventDefault() && false}>
                    <div className="input-container">
                        <label>Concepto de Venta</label>
                        <input value={concept} onChange={e => setconcept(e.target.value)} type="text"/>
                    </div>
                    <div className="input-container">
                        <label>Monto de Venta ($)</label>
                        <input value={amount} onChange={e => setamount(e.target.value)} type="number"/>
                    </div>
                    <div className="input-container">
                        <label>Cantidad de Cosecha (UM)</label>
                        <input value={quantity} onChange={e => setquantity(e.target.value)} type="number"/>
                    </div>
                    <div className="input-container">
                        <button onClick={handleSale} type="submit">Agregar Cosecha</button>
                    </div>
                </form>
                <table>
                    <thead>
                        <tr>
                            <th>Fecha</th>
                            <th>Concepto</th>
                            <th>Monto</th>
                            <th>Cantidad</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            sales.map(item => <tr key={item.id}>
                                <td>{moment(item.timestamp).format('DD-MM-YYYY')}</td>
                                <td>{item.concept}</td>
                                <td>{numeral(item.amount).format('$0,0.00')}</td>
                                <td>{numeral(item.quantity).format('0.00')}</td>
                            </tr>)
                        }
                    </tbody>
                </table>
            </div>
            <div className="harvest-operation__memo">
                <form onSubmit={e => e.preventDefault() && false}>
                    <div className="input-container">
                        <label>Detalle</label>
                        <textarea value={description} onChange={e => setdescription(e.target.value)} rows="10"></textarea>
                    </div>
                    <div className="input-container">
                        <button onClick={handleMemo} type="submit">Agregar a Minuta</button>
                    </div>
                </form>
                <table>
                    <thead>
                        <tr>
                            <th>Eliminar</th>
                            <th>Fecha</th>
                            <th>Detalle</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            memo.map(item => <tr key={item.id}>
                                <td><button onClick={() => firebase.delete('harvest-memo',item.id,item)}><i className="material-icons">delete</i></button></td>
                                <td>{moment(item.timestamp).format('DD-MM-YYYY')}</td>
                                <td>{item.description}</td>
                            </tr>)
                        }
                    </tbody>
                </table>
            </div>
        </div>
    )

    async function handleSale () {
        try {
            if (concept && amount && quantity) {
                const item = {
                    concept,
                    amount: Number(amount),
                    quantity: Number(quantity),
                    processId: id,
                    loteId: process.loteId,
                    seedId: process.seedId
                }
                firebase.simpleAdd(item,'harvest-sales').then(() => {
                    alert('Venta agregada')
                    setconcept('')
                    setamount('')
                    setquantity('')
                })
            } else {
                alert('Completa todos los campos para continuar')
            }
        } catch (error) {
            alert(error.message)
        }
    }
    async function handleMemo () {
        try {
            if (description) {
                const item = {
                    description,
                    processId: id,
                    loteId: process.loteId
                }
                firebase.simpleAdd(item,'harvest-memo').then(() => {
                    alert('Elemento agregado')
                    setdescription('')
                })
            } else {
                alert('Escribe una descripción para continuar')
            }
        } catch (error) {
            alert(error.message)
        }
    }
}