import React, { useState, useEffect } from 'react'
import firebase from '../../../firebase'
import numeral from 'numeral'
import moment from 'moment'
import _ from 'lodash'

const Item = (props) => {

    const {data} = props
    const filtered = props.moves.slice(0,Number(props.index) + 1);
    const totalIncomes = _.reduce(filtered, (sum,n) => {
        if (n.type === 'Entrada') {
            return sum + n.quantity
        }
        return sum
    },0)
    const totalExpenses = _.reduce(filtered, (sum,n) => {
        if (n.type === 'Salida') {
            return sum + n.quantity
        }
        return sum
    },0)
    const balance = totalIncomes - totalExpenses

    return (
        <tr>
            <td>{moment(data.date).format('DD-MM-YYYY')}</td>
            <td>{data.concept}</td>
            <td>{data.cause}</td>
            <td>{data.type === 'Entrada' ? numeral(data.quantity).format('0.0') : ''}</td>
            <td>{data.type === 'Salida' ? numeral(data.quantity).format('0.0') : ''}</td>
            <td>{numeral(balance).format('0.0')}</td>
        </tr>
    )
}

const SimplifiedSheepList = () => {

    const [moves,setMoves] = useState([])
    const [date,setdate] = useState('')
    const [concept,setconcept] = useState('')
    const [type,settype] = useState('')
    const [cause,setcause] = useState('')
    const [quantity,setquantity] = useState('')

    useEffect(() => {
        firebase.getCollectionRealTime('sheep-moves').orderBy('date').onSnapshot(snap => {
            const data = snap.docs.map(doc => doc.data())
            setMoves(data)
        })
    }, [])

    return (
        <div className="simplified-sheep-list">
            <form onSubmit={e => e.preventDefault() && false}>
            <div className="input-container">
                    <label>Fecha</label>
                    <input value={date} onChange={e => setdate(e.target.value)} placeholder="Fecha" type="date"/>
                </div>
                <div className="input-container">
                    <label>Concepto</label>
                    <input value={concept} onChange={e => setconcept(e.target.value)} placeholder="Concepto" type="text"/>
                </div>
                <div className="input-container">
                    <label>Tipo</label>
                    <select value={type} onChange={e => settype(e.target.value)}>
                        <option value="">** Seleccionar opción **</option>
                        <option value="Entrada">Entrada</option>
                        <option value="Salida">Salida</option>
                    </select>
                </div>
                {
                    type === 'Entrada' ? <div className="input-container">
                        <label>Tipo</label>
                        <select value={cause} onChange={e => setcause(e.target.value)}>
                            <option value="">** Seleccionar opción **</option>
                            <option value="Compra">Compra</option>
                            <option value="Nacimiento">Nacimiento</option>
                        </select>
                    </div> : type === 'Salida' ? <div className="input-container">
                        <label>Tipo</label>
                        <select value={cause} onChange={e => setcause(e.target.value)}>
                            <option value="">** Seleccionar opción **</option>
                            <option value="Venta">Venta</option>
                            <option value="Robo">Robo</option>
                            <option value="Muerte">Muerte</option>
                        </select>
                    </div> : ''
                }
                <div className="input-container">
                    <label>Cantidad</label>
                    <input value={quantity} onChange={e => setquantity(e.target.value)} placeholder="Cantidad" type="number"/>
                </div>
                <div className="input-container">
                    <button onClick={handleSubmit} type="submit">Agregar</button>
                </div>
            </form>
            <table>
                <thead>
                    <tr>
                        <th>Fecha</th>
                        <th>Concepto</th>
                        <th>Causa</th>
                        <th>Entrada</th>
                        <th>Salida</th>
                        <th>Balance</th>
                    </tr>
                </thead>
                <tbody>{Object.keys(moves).map(key => <Item key={key} index={key} data={moves[key]} moves={moves} />)}</tbody>
            </table>
        </div>
    )
    async function handleSubmit() {
        try {
            const item = {
                date: moment(date,'YYYY-MM-DD').valueOf(),
                concept,
                type,
                quantity: Number(quantity),
                cause
            }
            firebase.simpleAdd(item,'sheep-moves').then(() => {
                setdate('')
                setconcept('')
                settype('')
                setquantity('')
            })
        } catch (error) {
            alert(error.message)
        }
    }
}

export default SimplifiedSheepList