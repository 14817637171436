import React, {useEffect,useState} from 'react'
import firebase from '../../../firebase'
import moment from 'moment'

export default function SheepList () {

    const [identifier,setidentifier] = useState('')
    const [bornDate,setbornDate] = useState('')
    const [sex,setsex] = useState('Macho')
    const [breed,setbreed] = useState('')
    const [weight,setweight] = useState('')
    const [sheep,setSheep] = useState([])

    useEffect(() => {
        firebase.getCollectionRealTime('sheep').where('status','==','active').onSnapshot(snap => {
            const data = snap.docs.map(doc => doc.data())
            setSheep(data)
        })
    },[])

    return (
        <div className="sheep-list">
            <form onSubmit={e => e.preventDefault() && false}>
                <div className="input-container">
                    <label>Identificador</label>
                    <input value={identifier} onChange={e => setidentifier(e.target.value)} placeholder="Identificador" type="text"/>
                </div>
                <div className="input-container">
                    <label>Fecha de Nacimiento</label>
                    <input value={bornDate} onChange={e => setbornDate(e.target.value)} placeholder="Fecha de Nacimiento" type="date"/>
                </div>
                <div className="input-container">
                    <label>Sexo</label>
                    <select value={sex} onChange={e => setsex(e.target.value)}>
                        <option value="Macho">Macho</option>
                        <option value="Hembra">Hembra</option>
                    </select>
                </div>
                <div className="input-container">
                    <label>Peso</label>
                    <input value={weight} onChange={e => setweight(e.target.value)} placeholder="Fecha de Nacimiento" type="date"/>
                </div>
                <div className="input-container">
                    <label>Raza</label>
                    <select value={breed} onChange={e => setbreed(e.target.value)}>
                        <option value="">**  Selecciona una opción **</option>
                        <option value="R1">R1</option>
                        <option value="R2">R2</option>
                        <option value="R3">R3</option>
                    </select>
                </div>
                <div className="input-container">
                    <button type="submit">Agregar</button>
                </div>
            </form>
            <table>
                <thead>
                    <tr>
                        <th>Identificador</th>
                        <th>Nacimiento</th>
                        <th>Sexo</th>
                        <th>Raza</th>
                    </tr>
                </thead>
                <tbody>{sheep.map(item => <tr>
                    
                </tr>)}</tbody>
            </table>
        </div>
    )

    async function handleSubmit () {
        try {
            const item = {
                identifier,
                bornDate: moment(bornDate,'YYYY-MM-DD').valueOf(),
                sex,
                breed,
                weight: Number(weight),
                status: 'active'
            }
            firebase.simpleAdd(item,'sheeps').then(() => {
                setidentifier('')
                setbornDate('')
                setsex('Macho')
                setbreed('')
            })
        } catch (error) {
            alert(error.message)
        }
    }
}