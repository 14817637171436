import React, { useState, useEffect } from 'react'
import firebase from '../../../firebase'
import numeral from 'numeral'
import moment from 'moment'

export default function SimplifiedSheepPurchases () {

    const [sales,setSales] = useState([])
    const [date, setdate] = useState('')
    const [quantity, setquantity] = useState('')
    const [amount, setamount] = useState('')
    const [concept,setconcept] = useState('')
    const [category,setcategory] = useState('')
    
    useEffect(() => {
        firebase.getCollectionRealTime('sheep-purchases').onSnapshot(snap => {
            const data = snap.docs.map(doc => doc.data())
            setSales(data)
        })
    },[])

    return (
        <div style={{ padding: '2.5rem' }} className="simplified-sheep-sales">
            <form style={{ display: 'flex' }} onSubmit={e => e.preventDefault() && false}>
                <div className="input-container">
                    <label>Fecha</label>
                    <input onChange={e => setdate(e.target.value)} placeholder="Fecha" value={date} type="date" />
                </div>
                <div className="input-container">
                    <label>Concepto</label>
                    <input onChange={e => setconcept(e.target.value)} placeholder="Concepto" value={concept} type="text" />
                </div>
                <div className="input-container">
                    <label>Cantidad</label>
                    <input onChange={e => setquantity(e.target.value)} placeholder="Cantidad" value={quantity} type="number" />
                </div>
                <div className="input-container">
                        <label>Categoría</label>
                        <select value={category} onChange={e => setcategory(e.target.value)}>
                            <option value="">** Seleccionar opción **</option>
                            <option value="Comida">Comida</option>
                            <option value="Nutrientes">Nutrientes</option>
                            <option value="Medicinas">Medicinas</option>
                            <option value="M.O.">M.O.</option>
                            <option value="Veterinario">Veterinario</option>
                            <option value="Limpieza">Limpieza</option>
                            <option value="Otro">Otro</option>
                        </select>
                    </div>
                <div className="input-container">
                    <label>Monto Total</label>
                    <input onChange={e => setamount(e.target.value)} placeholder="Valor Total de Venta" value={amount} type="number" />
                </div>
                <div className="input-container">
                    <button onClick={handleSubmit} type="submit">Agregar</button>
                </div>
            </form>
            <table>
                <thead>
                    <tr>
                        <th>Fecha</th>
                        <th>Concepto</th>
                        <th>Cantidad</th>
                        <th>Categoría</th>
                        <th>Monto</th>
                    </tr>
                </thead>
                <tbody>{
                    sales.map(item => <tr>
                        <td>{moment(item.date).format('DD-MM-YYYY')}</td>
                        <td>{item.concept}</td>
                        <td>{item.quantity}</td>
                        <td>{item.category}</td>
                        <td>{numeral(item.amount).format('$0,0.00')}</td>
                    </tr>)
                }</tbody>
            </table>
        </div>
    )

    async function handleSubmit () {
        try {
            const item = {
                date: moment(date, 'YYYY-MM-DD').valueOf(),
                quantity: Number(quantity),
                amount: Number(amount),
                type: 'sheep',
                concept,
                category
            }
            firebase.simpleAdd(item,'sheep-purchases').then(() => {
                setdate('')
                setquantity('')
                setamount('')
                setconcept('')
                setcategory('')
                alert('Compra registrada')
            })
        } catch (error) {
            alert(error.message)
        }
    }
}