import React, { useState, useEffect } from 'react'
import firebase from '../../../firebase'
import numeral from 'numeral'
import moment from 'moment'
import _ from 'lodash'

const Item = ({ data,moves,index }) => {

    const filtered = moves.slice(0,Number(index) + 1);
    const totalIncomes = _.reduce(filtered, (sum,n) => {
        if (n.type === 'ingreso') {
            return sum + n.amount
        }
        return sum
    },0)
    const totalExpenses = _.reduce(filtered, (sum,n) => {
        if (n.type === 'egreso') {
            return sum + n.amount
        }
        return sum
    },0)
    const balance = totalIncomes - totalExpenses

    return (
        <tr>
            <td>{moment(data.date).format('DD-MM-YYYY')}</td>
            <td>{data.concept}</td>
            <td>{data.type === 'ingreso' ? numeral(data.amount).format('$0,0.00') : '' }</td>
            <td>{data.type === 'egreso' ? numeral(data.amount).format('$0,0.00') : '' }</td>
            <td>{numeral(balance).format('$0,0.00')}</td>
        </tr>
    )
}

export default function HarvestAccounting () {

    const [purchases,setPurchases] = useState([])
    const [sales,setSales] = useState([])

    useEffect(() => {
        firebase.getCollectionOnce('harvest-purchases').then(snap => {
            const data = snap.docs.map(doc => ({ amount: doc.data().amount, type: 'egreso', date: doc.data().date, concept: `${doc.data().concept} - Cantidad: ${doc.data().quantity} - ${doc.data().category}` }))
            setPurchases(data)
        })
        firebase.getCollectionOnce('harvest-sales').then(snap => {
            const data = snap.docs.map(doc => ({ amount: doc.data().amount, type: 'ingreso', date: doc.data().timestamp, concept: `Venta de ${doc.data().quantity} borregos` }))
            setSales(data)
        })
    },[])

    const joined = _.concat(purchases,sales)
    const moves = _.orderBy(joined, ['date'], ['asc'])

    return (
        <div style={{ padding: '2.5rem' }}>
            <table>
                <thead>
                    <tr>
                        <th>Fecha</th>
                        <th>Concepto</th>
                        <th>Ingresos</th>
                        <th>Egresos</th>
                        <th>Balance</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        Object.keys(moves).map(key => <Item key={key} index={key} data={moves[key]} moves={moves} />)
                    }
                </tbody>
            </table>
        </div>
    )
}