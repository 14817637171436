import app from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/storage'
import 'firebase/functions'
import moment from 'moment'

const config = {
    apiKey: "AIzaSyBqG3cx5d_k0AAZSntGQejg28J3t2kdce0",
    authDomain: "sistema-lohr.firebaseapp.com",
    databaseURL: "https://sistema-lohr.firebaseio.com",
    projectId: "sistema-lohr",
    storageBucket: "sistema-lohr.appspot.com",
    messagingSenderId: "553229372576",
    appId: "1:553229372576:web:0d14d53ab39908c2"
}

class Firebase {
	constructor() {
		app.initializeApp(config)
		this.auth = app.auth()
		this.firestore = app.firestore()
	}

	login(email, password) {
		return this.auth.signInWithEmailAndPassword(email, password)
	}

	logout() {
		return this.auth.signOut()
	}

	async register(name, email, password) {
		await this.auth.createUserWithEmailAndPassword(email, password)
		return this.auth.currentUser.updateProfile({
			displayName: name
		}).then(() => {
            return this.firestore.doc(`users/${this.auth.currentUser.uid}`).set({
                name,
                id: this.auth.currentUser.uid,
                rol: 'admin',
                isActive: false,
                email,
                password
            })
        })
    }

    userValidator() {
        return new Promise(resolve => {
            this.firestore.doc(`users/${this.auth.currentUser.uid}`).get().then(snap => {
                const user = snap.data();
                if (user.isActive === 'true' && user.rol === "admin") {
                    resolve(true)
                } else if (user.isActive === 'true' && user.isRancho === 'true') {
                    resolve(true)
                } else {
                    resolve(null)
                }
            });
        });
    }

	isInitialized() {
		return new Promise(resolve => {
			this.auth.onAuthStateChanged(resolve)
		})
	}

	getCurrentUsername() {
		return this.auth.currentUser && this.auth.currentUser.displayName
	}
    
    getUsers() {
        const users = this.firestore.collection(`users`);
        return users
    }
    getCollectionOnce(col) {
        const data = this.firestore.collection(col).get()
        return data
    }
    getCollectionRealTime(col) {
        const data = this.firestore.collection(col)
        return data
    }
    getDocumentOnce(col,doc) {
        const data = this.firestore.collection(col).doc(doc).get()
        return data
    }
    getDocumentRealTime(col,doc) {
        const data = this.firestore.collection(col).doc(doc)
        return data
    }

    async simpleAdd(item,col) {
        const ref = this.firestore.collection(col).doc()
        item.id = ref.id
        item.timestamp = moment().valueOf()
        await ref.set(item);
        const movesRef = this.firestore.collection('usersMoves').doc();
        const move = {
            col,
            timestamp: moment().valueOf(),
            id: movesRef.id,
            type: 'Agregar',
            what: ref.id,
            userId: this.auth.currentUser.uid
        };
        return movesRef.set(move);
    }
    async delete(col,doc,data) {
        const ref = this.firestore.collection(col).doc(doc)
        await ref.delete();
        const movesRef = this.firestore.collection('usersMoves').doc();
        const move = {
            col,
            timestamp: moment().valueOf(),
            id: movesRef.id,
            type: 'Borrar',
            what: ref.id,
            userId: this.auth.currentUser.uid,
            data
        };
        return movesRef.set(move);
    }
    update(col,id,index,value) {
        return this.firestore.collection(col).doc(id).update({ [index]: value });
    }
}

export default new Firebase()