import React, { useState, useEffect } from 'react'
import { Link, Route, Switch } from 'react-router-dom'
import SimplifiedSheepList from './simplified-sheep-list/SimplifiedSheepList';
import SimplifiedSheepSales from './simplified-sheep-sales/SimplifiedSheepSales';
import SimplifiedSheepPurchases from './simplified-sheep-purchases/SimplifiedSheepPurchases';
import SimplifiedSheepListAccounting from './simplified-sheep-list-accounting/SimplifiedSheepListAccounting';

const SimplifiedSheep = (props) => {

    const loc = props.location.pathname.split('/')[2];
    
    return (
        <div className="sheep">
            <div className="in-nav">
                <ul>
                    <Link className={loc === undefined ? 'active' : ''} to="/borregos">Lista</Link>
                    <Link className={loc === 'ventas' ? 'active' : ''} to="/borregos/ventas">Ventas</Link>
                    <Link className={loc === 'compras' ? 'active' : ''} to="/borregos/compras">Compras</Link>
                    <Link className={loc === 'estado-cuenta' ? 'active' : ''} to="/borregos/estado-cuenta">Estado de Cuenta</Link>
                    {/* <Link className={loc === 'estado-resultados' ? 'active' : ''} to="/borregos/estado-resultados">Estado de Resultados</Link> */}
                </ul>
            </div>
            <Switch>
                <Route path="/borregos" component={SimplifiedSheepList} exact={true} />
                <Route path="/borregos/ventas" component={SimplifiedSheepSales} exact={true} />
                <Route path="/borregos/compras" component={SimplifiedSheepPurchases} exact={true} />
                <Route path="/borregos/estado-cuenta" component={SimplifiedSheepListAccounting} exact={true} />
            </Switch>
        </div>
    )
}

export default SimplifiedSheep