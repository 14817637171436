import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import firebase from '../../../firebase'
import moment from 'moment'
import numeral from 'numeral'
import _ from 'lodash'

const Item = ({ data }) => {

    const [seed,setSeed] = useState({})

    useEffect(() => {
        firebase.getDocumentOnce('seeds',data.seedId).then(snap => {
            setSeed(snap.data())
        })
    },[data.seedId])

    return (
        <tr>
            <td>{moment(data.timestamp).format('DD-MM-YYYY')}</td>
            <td>{seed ? seed.name : ''}</td>
            <td className="center"><select value={data.status} onChange={e => firebase.update('harvest-process',data.id,'status',e.target.value)}>
                <option value="En Proceso">En Proceso</option>
                <option value="Cosechado">Cosechado</option>
            </select></td>
            <td>{data.quantity}</td>
        </tr>
    )
}

export default function HarvestLote (props) {

    const id = props.match.params.id
    const [lote,setLote] = useState({})
    const [seeds,setSeeds] = useState([])
    const [process,setProcess] = useState([])
    const [seedId,setseedId] = useState('')
    const [quantity,setquantity] = useState('')
    const [description,setdescription] = useState('')
    const [memo,setMemo] = useState([])
    const [purchases,setPurchases] = useState([])

    useEffect(() => {
        firebase.getDocumentRealTime('lotes',id).onSnapshot(snap => {
            setLote(snap.data())
        })
        firebase.getCollectionOnce('seeds').then(snap => {
            const data = snap.docs.map(doc => doc.data())
            setSeeds(data)
        })
        firebase.getCollectionRealTime('harvest-process').where('loteId','==',id).onSnapshot(snap => {
            const data = snap.docs.map(doc => doc.data())
            setProcess(data)
        })
        firebase.getCollectionRealTime('harvest-memo').where('loteId','==',id).orderBy('timestamp','desc').onSnapshot(snap => {
            const data = snap.docs.map(doc => doc.data())
            setMemo(data)
        })
        firebase.getCollectionRealTime('expenses').where('category','==','RANCHO').where('subCategory','==',id).orderBy('date').onSnapshot(snap => {
            const data = snap.docs.map(doc => doc.data())
            setPurchases(data)
        })
    },[id])

    const total = _.reduce(purchases, (sum,n) => {
        return sum + n.amount
    },0)

    return (
        <div className="harvest-lote">
            <div className="harvest-lote__side">
                <div className="input-container">
                    <label>Alias de Lote</label>
                    <input value={lote.alias} onChange={e => firebase.update('lotes',id,'alias',e.target.value)} type="text"/>
                </div>
                <div className="input-container">
                    <label>Descripción de Lote</label>
                    <textarea rows="6" value={lote.description} onChange={e => firebase.update('lotes',id,'description',e.target.value)} type="text"/>
                </div>
                <hr style={{ border: 'none',borderBottom: '1px solid #ccc' }} />
                <div className="harvest-operation__memo">
                    <form onSubmit={e => e.preventDefault() && false}>
                        <div className="input-container">
                            <label>Detalle</label>
                            <textarea value={description} onChange={e => setdescription(e.target.value)} rows="4"></textarea>
                        </div>
                        <div className="input-container">
                            <button onClick={handleMemo} type="submit">Agregar a Minuta</button>
                        </div>
                    </form>
                    <table>
                        <thead>
                            <tr>
                                <th>Eliminar</th>
                                <th>Fecha</th>
                                <th>Detalle</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                memo.map(item => <tr key={item.id}>
                                    <td><button onClick={() => firebase.delete('harvest-memo',item.id,item)}><i className="material-icons">delete</i></button></td>
                                    <td>{moment(item.timestamp).format('DD-MM-YYYY')}</td>
                                    <td>{item.description}</td>
                                </tr>)
                            }
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="harvest-lote__container">
                <form onSubmit={e => e.preventDefault() && false}>
                    <div className="input-container">
                        <label>Semillas a plantar</label>
                        <select value={seedId} onChange={e => setseedId(e.target.value)}>
                            <option value="">** Seleccionar una opción **</option>
                            {
                                seeds.map(item => <option key={item.id} value={item.id}>{item.name}</option>)
                            }
                        </select>
                    </div>
                    <div className="input-container">
                        <label>Cantidad</label>
                        <input type="number" value={quantity} onChange={e => setquantity(e.target.value)} />
                    </div>
                    <div className="input-container">
                        <button onClick={handleSubmit} type="submit">Agregar</button>
                    </div>
                </form>
                <table>
                    <thead>
                        <tr>
                            <th>Fecha</th>
                            <th>Semilla</th>
                            <th>Estatus</th>
                            <th>Cantidad</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            process.map(item => <Item key={item.id} data={item} />)
                        }
                    </tbody>
                </table>

                <h3>Gastos</h3>
                <table style={{ marginTop: '2.5rem' }}>
                    <thead>
                        <tr>
                            <th>Fecha</th>
                            <th>Concepto</th>
                            <th>Monto</th>
                        </tr>
                    </thead>
                    <tbody>{
                        purchases.map(item => <tr key={item.id}>
                            <td>{moment(item.date).format('DD-MM-YYYY')}</td>
                            <td>{item.concept}</td>
                            <td>{numeral(item.amount).format('$0,0.00')}</td>
                        </tr>)
                    }</tbody>
                    <tfoot>
                        <tr>
                            <th></th>
                            <th>Total</th>
                            <td>{numeral(total).format('$0,0.00')}</td>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </div>
    )

    async function handleMemo () {
        try {
            if (description) {
                const item = {
                    description,
                    loteId: id
                }
                firebase.simpleAdd(item,'harvest-memo').then(() => {
                    alert('Elemento agregado')
                    setdescription('')
                })
            } else {
                alert('Escribe una descripción para continuar')
            }
        } catch (error) {
            alert(error.message)
        }
    }

    async function handleSubmit () {
        try {
            if (seedId && quantity) {
                const item = {
                    seedId,
                    quantity: Number(quantity),
                    loteId: id,
                    status: 'En Proceso'
                }
                firebase.simpleAdd(item,'harvest-process').then(() => {
                    setseedId('')
                    setquantity('')
                })
            } else {
                alert('Completa todos los campos para continuar')
            }
        } catch (error) {
            alert(error.message)
        }
    }
}